<template>
  <ActionsMini
    v-if="mini || mobileCollapse.length"
    @action="handle"
    v-bind="$attrs"
    :icon="miniIcon"
    :actions="mini ? items : mobileCollapse"
    :variant="variant"
    :elevation="elevation"
    :color="color"
    :disabled="disabled"
    :size="size"
  />
  <template v-if="!mini">
    <template v-for="action in items" :key="action.key">
      <ActionItem
        v-if="action.primary || !mobile || noCollapse"
        class="text-none"
        @action="handle"
        :action="action"
        :variant="variant"
        :elevation="elevation"
        :color="color"
        :size="size"
        :height="height"
        :width="width"
        :disabled="disabled"
      />
    </template>
  </template>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import { useDisplay } from "vuetify";
import type { AppAction } from "../services/actions/types";
import ActionItem from "../private/actions/ActionItem.vue";
import ActionsMini from "../private/actions/ActionsMini.vue";
import { handleClick } from "../private/actions/lib";

export default defineComponent({
  name: "AppActions",
  inheritAttrs: false,
  props: {
    actions: { type: Array as PropType<AppAction[]>, required: true },
    miniIcon: { type: String as PropType<string> },
    mini: { type: Boolean, default: false },
    noCollapse: { type: Boolean, default: false },
    broadcast: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    variant: { type: String },
    color: { type: String },
    elevation: { type: [String, Number] },
    size: { type: [String, Number] },
    height: { type: [String, Number] },
    width: { type: [String, Number] },
    namespace: { type: String },
    payload: {},
  },
  components: { ActionItem, ActionsMini },
  setup() {
    const { mobile } = useDisplay();

    return {
      mobile,
    };
  },
  methods: {
    handle: handleClick,
  },
  computed: {
    items() {
      if (this.actions) {
        return this.actions.filter((action) => !action.hidden);
      }
      return [];
    },
    mobileCollapse() {
      if (!this.items || this.noCollapse) return [];
      return this.mobile ? this.items.filter((item) => !item.primary) : [];
    },
  },
});
</script>
