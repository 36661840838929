import type { ComponentPublicInstance } from "vue";
import type { AppAction } from "../../services/actions/types";

interface ComponentInstance {
  broadcast: boolean;
  payload?: any;
  namespace?: string;
}

export function handleClick(
  this: ComponentPublicInstance & ComponentInstance,
  item: AppAction
) {
  if (item.items || !item.key) {
    // ignore groups
    return;
  }
  this.$emit("action", item);
  this.$emit("action:" + item.key, this.payload);
  if (this.broadcast) {
    this.$broadcast(item.key, this.payload);
  }
  if (this.namespace) {
    const { dispatch } = useNamespacedEvent(this.namespace);
    dispatch(item.key, this.payload);
  }
}
