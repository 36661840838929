<template>
  <v-btn
    :color="c"
    :variant="v"
    :to="action.to"
    :loading="action.loading"
    :disabled="disabled || action.disabled"
    :size="size"
    :height="height"
    :width="width"
    :elevation="action.elevation || elevation"
    @click="handle(action)"
  >
    <AppIcon v-if="action.icon" :name="action.icon" class="mr-1" />
    {{ action.title }}
    <!-- <span
      class="bg-gray/20 rounded-md h-20px w-20px ml-2 flex-center"
    >
      <span>n</span>
    </span> -->
    <template v-if="action.items">
      <v-icon>expand_more</v-icon>
      <v-menu
        activator="parent"
        location="bottom"
        transition="slide-y-transition"
        offset="15"
      >
        <ActionItemDropdown @action="handle" :items="action.items" />
      </v-menu>
    </template>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { AppAction } from "../../services/actions/types";
import ActionItemDropdown from "./ActionItemDropdown.vue";
import { handleClick } from "./lib";

export default defineComponent({
  name: "AppActions",
  props: {
    action: { type: Object as PropType<AppAction>, required: true },
    broadcast: { type: Boolean, default: false },
    variant: { type: String },
    elevation: { type: [String, Number] },
    disabled: { type: Boolean, default: false },
    color: { type: String },
    size: { type: [String, Number] },
    height: { type: [String, Number] },
    width: { type: [String, Number] },
  },
  components: { ActionItemDropdown },
  methods: {
    handle: handleClick,
  },
  computed: {
    v(): any {
      return (
        this.variant ||
        (this.action.primary
          ? "elevated"
          : this.elevation || this.action.elevation
          ? undefined
          : "tonal")
      );
    },
    c(): any {
      return (
        this.action.color || (this.action.primary ? "primary" : this.color)
      );
    },
  },
});
</script>
