<template>
  <v-card class="border" rounded="lg">
    <v-list class="py-2">
      <template v-for="(item, i) in items" :key="item.key">
        <template v-if="item.items">
          <v-divider v-if="i" class="mt-2" />
          <v-list-subheader>{{ item.title }}</v-list-subheader>
          <v-list-item
            v-for="subItem in item.items"
            :key="subItem.key"
            class="mx-2 px-2"
            rounded="lg"
            @click="handle(subItem)"
            :to="subItem.to"
            :disabled="item.disabled || item.loading"
          >
            <template #prepend>
              <AppIcon v-if="subItem.icon" :name="subItem.icon" class="mr-2" />
            </template>
            <template #title>
              <span class="text-3.5">{{ subItem.title }}</span>
            </template>
            <template #append>
              <v-progress-circular
                v-if="item.loading"
                class="ml-2"
                size="15"
                width="1"
                indeterminate
              />
            </template>
          </v-list-item>
        </template>
        <v-list-item
          v-else
          class="mx-2 px-2"
          rounded="lg"
          @click="handle(item)"
          :to="item.to"
          :disabled="item.loading"
        >
          <template #prepend>
            <AppIcon v-if="item.icon" :name="item.icon" class="mr-2" />
          </template>
          <template #title>
            <span class="text-3.5">{{ item.title }}</span>
          </template>
          <template #append>
            <v-progress-circular
              v-if="item.loading"
              class="ml-2"
              size="15"
              width="1"
              indeterminate
            />
          </template>
        </v-list-item>
      </template>
    </v-list>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { AppAction } from "../../services/actions/types";
import { handleClick } from "./lib";

export default defineComponent({
  props: {
    items: { type: Array as PropType<AppAction[]>, required: true },
    broadcast: { type: Boolean, default: false },
  },
  methods: {
    handle: handleClick,
  },
});
</script>
