<template>
  <v-btn
    :variant="variant"
    :size="size"
    :color="color"
    :elevation="elevation"
    :disabled="disabled"
    icon
  >
    <AppIcon :name="icon" />
    <v-menu
      activator="parent"
      location="bottom"
      transition="slide-y-transition"
      offset="15"
    >
      <ActionItemDropdown @action="handle" :items="actions" />
    </v-menu>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType } from "vue";
import type { AppAction } from "../../services/actions/types";
import ActionItemDropdown from "./ActionItemDropdown.vue";
import { handleClick } from "./lib";

export default defineComponent({
  props: {
    actions: { type: Array as PropType<AppAction[]>, required: true },
    broadcast: { type: Boolean, default: false },
    icon: { type: String as PropType<string>, default: "more_horiz" },
    size: { type: [String, Number], default: 30 },
    color: { type: String },
    variant: { type: String as PropType<any>, default: "tonal" },
    elevation: { type: [String, Number] },
    disabled: { type: Boolean, default: false },
    payload: {},
    namespace: { type: String },
  },
  components: { ActionItemDropdown },
  methods: {
    handle: handleClick,
  },
});
</script>
